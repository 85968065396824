import { Component } from '@angular/core';
import packageJson from '../../package.json';
import { ConnectorService } from './connector.service';
import { SwPush } from '@angular/service-worker';
import { NotificationsService } from './notifications.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-sociochaux',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Sociochaux : Espaces Personnels';
  public version: string = packageJson.version;

  readonly VAPID_PUBLIC_KEY = "BO4pSTCsdbSodX8VnKq-5M34_P707I-SMpbDPOnZNBw3DKpuVCieTcHM1f6yAMqMLMrCyZfbXrLVv5W5T2i3zSs";
  eventSource:EventSource|null = null;

  constructor(
    private connector: ConnectorService,
    private swPush: SwPush,
    private notificationsService: NotificationsService
  ){}

  ngOnInit(): void {
    if(this.connector.isConnected()){
      this.subscribeToNotifications();
    }
  }

  subscribeToNotifications() {
  }

  getYear(){
    return (new Date()).getFullYear();
  }

  isConnected(){
    return this.connector.isConnected();
  }
}
