import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { AccountComponent } from './account/account.component';
import { ConsultationsComponent } from './consultations/consultations.component';
import { DocumentsComponent } from './documents/documents.component';
import { PronosComponent } from './pronos/pronos.component';
import { HomeComponent } from './home/home.component';
import { AdhesionComponent } from './adhesion/adhesion.component';

const routes: Routes = [
  { path: 'home', component: HomeComponent },
  { path: 'login', component: LoginComponent },
  { path: 'adhesion', component: AdhesionComponent },
  { path: 'account', component: AccountComponent },
  { path: 'consultations', component: ConsultationsComponent },
  { path: 'documents', component: DocumentsComponent },
  { path: 'documents/:id', component: DocumentsComponent },
  { path: 'pronos', component: PronosComponent },
  { path: '', component: LoginComponent },
  { path: '404', component: LoginComponent},
  { path: '**', component: LoginComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
