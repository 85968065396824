import { Component, inject } from '@angular/core';
import { ConnectorService } from '../connector.service';
import { NavigationEnd, Router } from '@angular/router';
import { MatSnackBar, MatSnackBarAction, MatSnackBarActions, MatSnackBarLabel, MatSnackBarRef } from '@angular/material/snack-bar';
import { MatButtonModule } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';

class navMenu {
  link: string = '';
  title: string = '';
  icon: string = '';
}

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent {
  isConnected = true;
  readonly connectedMenus: navMenu[] = [];
  menuToDisplay: navMenu[] = [];

  deferredPrompt: any = null;

  constructor(private connector: ConnectorService, private router: Router, private _snackBar: MatSnackBar) {}

  ngOnInit(): void {
    this.setIsConnected();
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.setIsConnected();
      }
    });
    this.checkInstall();
  }

  // Detects if device is on iOS
  isIos(){
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /iphone|ipad|ipod|macintosh/.test(userAgent);
  }
                                
  // Detects if device is on iOS
  isSafari(){
    return navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
        navigator.userAgent &&
        navigator.userAgent.indexOf('CriOS') === -1 &&
        navigator.userAgent.indexOf('FxiOS') === -1;
  }

  isMobile = () => {
    return window.innerWidth < 1025
  }

  checkInstall() {
    const _this = this;
    let installPrompt:any = null;
    const installButton:any = document.querySelector("#install");
    if(!_this.isMobile()){
      installButton.style.display = 'none';
    }

    window.addEventListener("beforeinstallprompt", (event) => {
      event.preventDefault();
      installPrompt = event;
      installButton.removeAttribute("hidden");
    });

    installButton.addEventListener("click", async () => {
      if(_this.isIos()){
        _this._snackBar.openFromComponent(IosSnackBarAnnotatedComponent);
      }else{
        if (!installPrompt) {
          return;
        }
        const result = await installPrompt.prompt();
        console.log(`Install prompt was: ${result.outcome}`);
        installPrompt = null;
        installButton.setAttribute("hidden", "");
      }
    });
  }

  setIsConnected() {
    this.isConnected = this.connector.isConnected();
    if (this.isConnected) {
      this.menuToDisplay = this.connectedMenus;
    } else {
      this.menuToDisplay = [];
    }
  }

  disconnect() {
    this.connector.disconnection();
  }
}

@Component({
  selector: 'ios-snackbar',
  templateUrl: 'ios.snackbar.html',
  styles: [],
  standalone: false
})
export class IosSnackBarAnnotatedComponent {
  snackBarRef = inject(MatSnackBarRef);
}
