<div fxLayout="column" [id]="'home'" class="mainDiv">
    <div flex="" fxLayout="column" fxLayoutAlign="center">
        <div fxFlex="80" style="padding-left: 20px; padding-right: 20px;">
            <p>Bonjour&nbsp;<strong>{{ getUsername() }}</strong> !</p>
            <p>Bienvenue sur ton espace MySociochaux, voici le menu :</p>
        </div>
        <div [fxFlex]="50" style="margin-top: 20px;" fxLayoutAlign="center">
            <button mat-raised-button color="primary" fxFlex="80" fxLayoutAlign="center center" [routerLink]="['/account']">
                <mat-icon>account_circle</mat-icon>
                <span>Mon profil</span>
            </button>
        </div>
        <div [fxFlex]="50" style="margin-top: 20px;" fxLayoutAlign="center">
            <button mat-raised-button color="primary" fxFlex="80" fxLayoutAlign="center center" [routerLink]="['/pronos']">
                <mat-icon>check_box</mat-icon>
                <span>Mes Pronostics</span>
            </button>
        </div>
        <div [fxFlex]="50" style="margin-top: 20px;" fxLayoutAlign="center">
            <button mat-raised-button color="primary" fxFlex="80" fxLayoutAlign="center center" [routerLink]="['/consultations']">
                <mat-icon>how_to_vote</mat-icon>
                <span>Mes Consultations / votes</span>
            </button>
        </div>
        <div [fxFlex]="50" style="margin-top: 20px;" fxLayoutAlign="center">
            <button mat-raised-button color="primary" fxFlex="80" fxLayoutAlign="center center" [routerLink]="['/documents']">
                <mat-icon>folder_open</mat-icon>
                <span>Documents partagés</span>
            </button>
        </div>
    </div>
</div>