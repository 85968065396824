<div class="dialog" fxLayout="column">
    <h1 mat-dialog-title flex="100">{{data.title}}</h1>
    <div mat-dialog-content fxLayout="column">
        <p flex="100">Veuillez saisir votre <strong>nouveau mot de passe</strong></p>
        <mat-form-field fxFlex="100">
            <mat-label>Mot de passe</mat-label>
            <input matInput type="password" [(ngModel)]="data.password">
        </mat-form-field>
        <mat-form-field fxFlex="100">
            <mat-label>Confirmation</mat-label>
            <input matInput type="password" [(ngModel)]="data.confirmation">
            <mat-icon matSuffix *ngIf="data.password === data.confirmation" style="color: green">verified</mat-icon>
            <mat-icon matSuffix *ngIf="data.password !== data.confirmation" style="color: orange">error</mat-icon>
            <mat-hint></mat-hint>
        </mat-form-field>
        <mat-card fxFlex="100" *ngIf="data.password !== data.confirmation || data.password.length < 5" style="color: red; background-color: pink; overflow-wrap: anywhere;">
            <mat-card-content>Le mot de passe et la confirmation doivent<br />contenir la même valeur (taille mini 5 caractères)</mat-card-content>
        </mat-card>
    </div>
    <div mat-dialog-actions align="center">
        <button mat-raised-button (click)="onNoClick()" color="warn" *ngIf="data.display === true">Annuler</button>
        <button mat-raised-button [mat-dialog-close]="data.password" [disabled]="data.password !== data.confirmation || data.password.length < 5" color="primary" cdkFocusInitial>Valider</button>
    </div>
</div>