import { Component, Inject, inject, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormsModule, Validators } from '@angular/forms';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { ConnectorService, UserVariable } from '../connector.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { ISO_3166_1_CODES } from '../account/country-codes';
import { COUNTRIES } from '../account/country-data-store';
import { PhoneErrorMatcher, phoneValidator } from '../account/account.component';
import { getExample, ParsedPhoneNumber, parsePhoneNumber } from 'awesome-phonenumber';
import { DialogPwdData, MyErrorStateMatcher } from '../login/login.component';
import { Account } from '../account/account.dto';
import { environment } from '../../environments/environment';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';

class DisplaySummary {
  id: number = 0;
  text: string = '';
  number: number = 0;
  total: number = 0;
  complement: number = 0;
}

enum StatusMessage {
  NOTHING = 'nothing',
  WAITING = 'waiting',
  OK = 'ok',
  CANCELED = 'canceled',
  ERROR = 'error'
}

export class ContributionSocio {
  constructor(id:number, contribution:number){
    this.id = id;
    this.contribution = contribution;
  }

  id:number = 0;
  contribution:number = 0;
}

export class AdhesionRequest {
  isSocio: boolean = false;
  choice_transport: number = 0;
  montant_complementaire: number = 0;
  // SOCIO
  socioContributions:ContributionSocio[] = [];

  // NON SOCIO
  nom: string = "";
  prenom: string = "";
  email: string = "";
  date_naissance: number = 0;
  adresse: string = "";
  code_postal: string = "";
  ville: string = "";
  pays: string = "France";
  phone: ParsedPhoneNumber|undefined = undefined;
  montant_contribution: number = 0;
}

@Component({
  selector: 'app-adhesion',
  templateUrl: './adhesion.component.html',
  styleUrls: ['./adhesion.component.scss'],
  providers: [
    {provide: MAT_DATE_LOCALE, useValue: 'fr-FR'},
  ],
})
export class AdhesionComponent {
  countyCodes = ISO_3166_1_CODES;
  public countries:any = COUNTRIES;
  public displayOk:boolean[] = [];
  phoneErrorMatcher = new PhoneErrorMatcher();
  panelCommandeOpenState = false;
  account:Account|null = null;
  messagePaiement:string = "";
  messageIntent:string = "";
  messageStatus:StatusMessage = StatusMessage.NOTHING;
  messageEmail:string = "";
  selectedStepIndex = 0;

  constructor(
    private http: HttpClient,
    private connector: ConnectorService,
    @Inject(MAT_DATE_LOCALE) private _locale: string,
    private router: Router,
    private _adapter: DateAdapter<any>,
    private currentRoute: ActivatedRoute,
    public dialog: MatDialog
  ){}

  ngOnInit(): void {
    const _this = this;
    this._locale = 'fr';
    this._adapter.setLocale(this._locale);
    if(this.isConnected()){
      setTimeout(function(){
        _this.loadSocios();
      },500);
    }
    this.currentRoute.queryParams
    .subscribe(params => {
      this.messagePaiement = params['paiement'];
      this.messageIntent = params['session'];
      if(this.messagePaiement !== undefined && this.messageIntent !== undefined){
        this.waitingForUpdate();
      }
    });
    setTimeout(function(){
      _this.loadCounters();
    },500);
  }

  private _formBuilder = inject(FormBuilder);

  adhesionFormGroup = this._formBuilder.group({
    adhesionCtrl: ['', Validators.required],
    complement: ['', Validators.min(0)],
  });
  accountFormGroup = this._formBuilder.group({
    accountCtrl: ['', Validators.required],
  });
  newSocioFormGroup = this._formBuilder.group({
    phone: this._formBuilder.group({
        country: ['FR', Validators.required],
        number: ['', Validators.required]
    }, {validators: phoneValidator}),
    email: ['', [Validators.required, Validators.email]],
    nom: ['', Validators.required],
    prenom: ['', Validators.required],
    date_naissance: ['', Validators.required],
    adresse: ['', Validators.required],
    code_postal: ['', Validators.required],
    ville: ['', Validators.required],
    pays: ['France', Validators.required],
  });
  emailFormControl = new FormControl('');
  passwordFormControl = new FormControl('');
  matcher = new MyErrorStateMatcher();
  message:string = "";
  isLinear = true;
  adhesionPaliers = [
    { id: 1, text: "J'adhère", price: 12, transport: false, complementaire: false, selectable: true, nb:-1, max: -1},
    { id: 2, text: "J'adhère et soutiens les projets", price: 24, transport: false, complementaire: false, selectable: true, nb:-1, max: -1},
    { id: 3, text: "J'adhère et reçois la BD collector", price: 38, transport: true, complementaire: true, selectable: true, nb:-1, max: -1},
    { id: 4, text: "Mécène : BD & affiche", price: 150, transport: true, complementaire: true, selectable: true, nb:-1, max: 100},
    { id: 5, text: "Bienfaiteur : BD dédicacée & affiche", price: 250, transport: true, complementaire: true, selectable: true, nb:-1, max: 50},
  ];
  transportsPaliers = [
    { id: 1, text: "Retrait boutique", price: 0},
    { id: 2, text: "Livraison en France", price: 9},
    { id: 3, text: "Livraison Europe hors Suisse", price: 15},
    { id: 4, text: "Livraison monde (dont Suisse)", price: 34}
  ];
  accountStatus = [
    { id: 1, text: "Je suis un nouvel Adhérent"},
    { id: 2, text: "Je suis déjà Adhérent"},
  ];

  hasAccount:number = 0;
  selectedPalier:number = 0;

  adhesion:AdhesionRequest = new AdhesionRequest();

  async waitingForUpdate(){
    this.messageStatus = StatusMessage.WAITING;
    const _this = this;
    await this.http.post<{email: string, result: string}>(environment.backAPI+'/payment/status',{paiement: this.messagePaiement, session: this.messageIntent}).subscribe(
      (result) => {
        if(result !== undefined && !(result instanceof HttpErrorResponse)){
          if(result.result === 'ok'){
            _this.messageStatus = StatusMessage.OK;
            _this.messageEmail = result.email;
          }
          if(result.result === 'canceled'){
            _this.messageStatus = StatusMessage.CANCELED;
            _this.messageEmail = result.email;
          }
          if(result.result === 'error'){
            _this.messageStatus = StatusMessage.ERROR;
          }
          if(result.result === 'waiting'){
            setTimeout(() => {
              _this.waitingForUpdate();
            },1000);
            return;
          }
        }else{
          _this.messageStatus = StatusMessage.ERROR;
        }
      },
      (error) => {
        _this.messageStatus = StatusMessage.ERROR;
      }
    );
  }

  hasComplement(id:number){
    const element = this.adhesionPaliers.find(palier => palier.id === id);
    return (element)?element.complementaire:false;
  }

  useComplement(){
    if(this.adhesion.isSocio){
      let hasComplement = false;
      for(let contrib of this.adhesion.socioContributions){
        hasComplement = hasComplement || this.hasComplement(contrib.contribution);
      }
      return hasComplement;
    }
    return this.hasComplement(this.selectedPalier);
  }

  isConnected(){
    return this.connector.isConnected();
  }

  isSelected(id:number){
    return this.isConnected() && id === 2 || !this.isConnected() && id === 1;
  }

  isSelectable(id:number){
    return this.isConnected() && id === 2 || !this.isConnected();
  }

  getConnectedEmail(){
    return this.connector.getValue(UserVariable.EMAIL);
  }

  updateUserForm(){
    let date_naissance:Date = new Date(this.newSocioFormGroup.get('date_naissance')?.value||'');
    this.adhesion.nom = this.newSocioFormGroup.get('nom')?.value||'';
    this.adhesion.prenom = this.newSocioFormGroup.get('prenom')?.value||'';
    this.adhesion.email = this.newSocioFormGroup.get('email')?.value||'';
    this.adhesion.date_naissance = date_naissance.getTime();
    this.adhesion.adresse = this.newSocioFormGroup.get('adresse')?.value||'';
    this.adhesion.code_postal = this.newSocioFormGroup.get('code_postal')?.value||'';
    this.adhesion.ville = this.newSocioFormGroup.get('ville')?.value||'';
    this.adhesion.pays = this.newSocioFormGroup.get('pays')?.value||'';
    this.adhesion.phone = this.phoneNumber;
    this.adhesion.isSocio = false;
    this.adhesion.montant_contribution = this.selectedPalier;
  }

  formatDate(date: Date): string {
    const day = String(date.getDate()).padStart(2, '0'); // Obtenir le jour et ajouter un zéro si nécessaire
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Obtenir le mois et ajouter un zéro si nécessaire
    const year = date.getFullYear(); // Obtenir l'année
  
    return `${day}-${month}-${year}`; // Retourner la date formatée
  }
  
  get phoneCountryControl() {
    return this.newSocioFormGroup.get('phone.country') as FormControl;
  }

  get phoneNumberDigits(): string {
      return this.phoneNumberControl.value.replace(/\D/g, '');
  }

  get phoneNumber(): ParsedPhoneNumber {
      return parsePhoneNumber( this.phoneNumberDigits, { regionCode: this.phoneCountryControl.value } );
  }
  // FormControl Getters
  get phoneGroup() {
      return this.newSocioFormGroup.get('phone') as FormControl;
  }
  /**
   * Generate a hint using the {@see PhoneNumber} getExample method
   * with the currently selected country.
   */
  get phoneHint(): string {
    const result:string|undefined = getExample(this.phoneCountryControl.value).number?.national;
    return (result !== undefined)?result:"";
  }

  get phoneNumberControl() {
    return this.newSocioFormGroup.get('phone.number') as FormControl;
  }

  formatNumber() {
      const natNum = this.phoneNumber.number?.national;
      this.phoneNumberControl.setValue((natNum) ? natNum : this.phoneNumberDigits);
  }

  async connection(){
    if(this.emailFormControl.getRawValue() !== null && this.passwordFormControl.getRawValue() !== null){
      await this.connector.inline_connection(this.emailFormControl.getRawValue(), this.passwordFormControl.getRawValue(), this);
      const _this = this;
      setTimeout(async () => {
        await _this.loadSocios();
        //if(this.isConnected()){
        //  this.selectedStepIndex += 2;
        //}
      },500);
    }else{
      alert("L'email et mot de passe sont requis")
    }
  }

  getCurrentPalier(){
    return this.adhesionPaliers.find(palier => palier.id === this.selectedPalier);
  }

  chooseBD(){
    const element = this.adhesionPaliers.find(palier => palier.id === this.selectedPalier);
    if(element === undefined){
      return false;
    }
    return element.transport;
  }

  hasTransport(){
    if(this.adhesion.isSocio){
      for(let contrib of this.adhesion.socioContributions){
        const element = this.adhesionPaliers.find(palier => palier.id === contrib.contribution);
        if(element && element.transport === true){
          return true;
        }
      }
    }else{
      const element = this.adhesionPaliers.find(palier => palier.id === this.adhesion.montant_contribution);
      if(element && element.transport === true){
        return true;
      }
    }
    return false;
  }

  socioSelection(){
    if(this.account && this.account.socios){
      return this.isConnected() && this.account?.socios?.length > 1;
    }
    return false;
  }

  nbNonAdherent(){
    return this.nonAdherent().length;
  }

  nonAdherent(){
    if(this.account && this.account.socios){
      return this.account.socios;
    }
    return [];
  }

  getSocio(id:number):ContributionSocio{
    const contrib = this.adhesion.socioContributions.find(contrib => contrib.id === id);
    return (contrib !== undefined)?contrib:new ContributionSocio(0,0);
  }

  setSocio(id:number, contribution:any|undefined){
    const contribSocio = this.getSocio(id);
    if(contribution !== undefined) contribSocio.contribution = Number(contribution.srcElement.value);
  }
  addSocio(id:number, contribution:number, complement:number){
    this.adhesion.socioContributions.push(new ContributionSocio(id, contribution));
  }

  changePalier(event:any){
    for(var contrib of this.adhesion.socioContributions){
      contrib.contribution = event.value;
    }
  }

  summaryToDisplay():DisplaySummary[]{
    const result:DisplaySummary[] = [];
    if(this.adhesion.isSocio){
      for(let contrib of this.adhesion.socioContributions){
        let summaryLine = result.find(resume => resume.id === contrib.contribution);
        const element = this.adhesionPaliers.find(palier => palier.id === contrib.contribution);
        if(summaryLine === undefined){
          summaryLine = new DisplaySummary();
          if(element !== undefined){
            summaryLine.id = element.id;
            summaryLine.text = element.text;
            result.push(summaryLine);
          }
        }
        summaryLine.total += element?.price||0;
        summaryLine.number++;
      }
    }else{
      const summaryLine = new DisplaySummary();
      const element = this.adhesionPaliers.find(palier => palier.id === this.selectedPalier);
      if(element !== undefined){
        summaryLine.id = element.id;
        summaryLine.text = element.text;
        summaryLine.total += element.price;
        summaryLine.number++;
        summaryLine.complement = 0;
      }
      result.push(summaryLine);
    }
    if(this.useComplement() && this.adhesion.montant_complementaire > 0){
      const summaryLine = new DisplaySummary();
      summaryLine.id = 0;
      summaryLine.text = "Don supplémentaire";
      summaryLine.total += (this.adhesion.montant_complementaire>0)?this.adhesion.montant_complementaire:0;
      summaryLine.number++;
      result.push(summaryLine);
    }
    if(this.hasTransport() && this.adhesion.choice_transport > 0){
      const summaryLine = new DisplaySummary();
      const element = this.transportsPaliers.find(palier => palier.id === this.adhesion.choice_transport);
      if(element !== undefined){
        summaryLine.id = element.id;
        summaryLine.text = element.text;
        summaryLine.total += element.price;
        summaryLine.number++;
        summaryLine.complement = 0;
      }
      result.push(summaryLine);
    }

    let total = 0;
    for(let line of result){
      total += line.total+((this.hasComplement(line.id) && line.complement>0)?line.complement:0);
    }
    let totalLine = new DisplaySummary();
    totalLine.id = -1;
    totalLine.text = 'Total';
    totalLine.total += total;
    result.push(totalLine);
    return result;
  }

  totalDisplay(id:number, value:number, isEuros:boolean):string{
    const add:string = (isEuros)?'€':'';
    return ((isEuros && value>0)?' + ':'')+((id < 0)?'':(value > 0)?value+add:(isEuros)?'':'-');
  }

  displayComplement(id:number){
    return  this.hasComplement(this.getSocio(id).contribution);
  }

  async loadSocios(){
    if(this.isConnected()){
      await this.http.get<Account>(environment.backAPI+'/payment/compte',{
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '+ this.connector.token()
        }
      }).subscribe(
        (result) => {
          if(result !== undefined && !(result instanceof HttpErrorResponse)){
            this.account = result;
            this.account.socios = this.account.socios.sort(function(a, b) {
              return a.techId - b.techId;
            });
            this.adhesion.socioContributions = [];
            for(var socio of this.nonAdherent()){
              this.addSocio(socio.id, this.selectedPalier, 0);
            }
            this.adhesion.isSocio = true;
          }
        }
      );
    }
  }

  async loadCounters(){
    await this.http.get<any>(environment.backAPI+'/payment/stats',{}).subscribe(
      (result) => {
        if(result !== undefined && !(result instanceof HttpErrorResponse)){
          const mecenes = this.adhesionPaliers.find(palier => palier.id === 4);
          const bienfaiteurs = this.adhesionPaliers.find(palier => palier.id === 5);
          if(mecenes && bienfaiteurs){
            mecenes.nb = mecenes.max - result.nbMecenes;
            bienfaiteurs.nb = bienfaiteurs.max - result.nbBienfaiteurs;
            mecenes.selectable = mecenes.nb >= 0;
            bienfaiteurs.selectable = bienfaiteurs.nb >= 0;
          }
        }
      }
    );
  }

  async sendAdhesion(){
    await this.http.post<{link: string, result: string}>(environment.backAPI+'/payment/subscription',this.adhesion).subscribe(
      (result) => {
        if(result !== undefined && !(result instanceof HttpErrorResponse)){
          if(result.result === 'OK'){
            window.location.href = result.link;
          }
          if(result.result === 'not_valid'){
            alert("Le formulaire saisi est incomplet: si vous êtes connecté, il est conseillé de vous déconnecter et reconnecter.");
          }
          if(result.result === 'email_used'){
            alert("L'adresse email saisie est déjà utilisée.");
          }
          if(result.result === 'socio_used'){
            alert("Le Socio sélectionné est déjà adhérent.");
          }
          if(result.result === 'bad_transport'){
            alert("Le moyen de livraison sélectionné n'est pas reconnu.");
          }
          if(result.result === 'email_used_as_socio'){
            alert("Vous possédez déjà un compte pour cette adresse email, veuillez vous connecter avant de compléter votre adhésion.");
          }
        }
      },
      (error) => {
        alert("Une erreur technique s'est produite");
      }
    );
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(DialogOverviewExampleDialog, {
      data: {email: this.emailFormControl.getRawValue()},
    });

    dialogRef.afterClosed().subscribe(objectReturned => {
      if(objectReturned !== undefined){
        this.http.post<boolean>(environment.backAPI+'/compte/reinit-pwd', objectReturned).subscribe(
          (result) => {
            alert("Votre demande a bien été prise en compte et un email vient de vous être envoyé");
          },
          (error) => {
            alert("Une erreur est survenue, votre demande n'a pas pu être prise en compte");
          }
        );
      }
    });
  }
}

@Component({
  selector: 'password-page-dialog',
  templateUrl: '../login/password-page-dialog.html',
  styleUrls: ['../login/password-page-dialog.css'],
  standalone: true,
  imports: [MatDialogModule, MatFormFieldModule, MatInputModule, FormsModule, FlexLayoutModule, MatButtonModule],
})
export class DialogOverviewExampleDialog {
  constructor(
    public dialogRef: MatDialogRef<DialogOverviewExampleDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogPwdData,
  ) {}

  regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

  onNoClick(): void {
    this.dialogRef.close();
  }
}
