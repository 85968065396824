<div class="dialog" fxLayout="column">
    <h1 mat-dialog-title fxFlex="100">Réinitialisation du mot de passe</h1>
    <div mat-dialog-content fxLayout="column">
        <p flex="100">Veuillez saisir votre adresse email<br />utilisée pour la levée de fonds</p>
        <mat-form-field fxFlex="100">
            <mat-label>Email du compte</mat-label>
            <input matInput type="email" [(ngModel)]="data.email">
        </mat-form-field>
    </div>
    <div mat-dialog-actions align="center">
        <button mat-raised-button (click)="onNoClick()" color="warn">Annuler</button>
        <button mat-raised-button [mat-dialog-close]="{ email: data.email }" [disabled]="!regexp.test(data.email)" color="primary" cdkFocusInitial>Valider</button>
    </div>
</div>