import { Component } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { ConnectorService } from '../connector.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DocumentDto } from './document.dto';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss']
})
export class DocumentsComponent {
  fileList: DocumentDto[] = [];
  fileListObs: Observable<DocumentDto[]> = new Observable();

  constructor(
    private http: HttpClient,
    private connector: ConnectorService,
    public dialog: MatDialog,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ){}

  ngOnInit(): void {
    if(!this.connector.isConnected()){
      this.connector.disconnection();
    }else{
      this.getDocuments();
    }
  }
  
  async getDocuments(){
    const _this = this;
    this.fileListObs = await this.http.get<DocumentDto[]>(environment.backAPI+'/documents/public',{
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+ this.connector.token()
      }
    });
    this.fileListObs.subscribe(
      (result) => {
        if(result !== undefined && !(result instanceof HttpErrorResponse)){
          _this.fileList = result;
          const testId = _this.activatedRoute.snapshot.params['id'];
          if(testId !== undefined){
            _this.fileDownload(Number(testId));
          }
          return result;
        }
        else{
          this.connector.disconnection();
          return [];
        }
      },
      (error) => {
        this.connector.disconnection();
        return [];
      }
    );
  }

  formatBytes(bytes:number,decimals: number) {
    if(bytes == 0) return '0 Bytes';
    var k = 1024,
        dm = decimals || 2,
        sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
        i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  async fileDownload(id:number){
    const downloadName = (await this.fileList.find(x => x.id == id))?.filename;
    if(downloadName !== undefined){
      let anchor = document.createElement("a");
      document.body.appendChild(anchor);
      let file = environment.backAPI+'/documents/public/'+id;
  
      let headers = new Headers();
      headers.append('Authorization', 'Bearer '+this.connector.token());
  
      fetch(file, { headers })
          .then(response => response.blob())
          .then(blobby => {
              let objectUrl = window.URL.createObjectURL(blobby);
  
              anchor.href = objectUrl;
              anchor.download = downloadName;
              anchor.click();
  
              window.URL.revokeObjectURL(objectUrl);
      });
    }else{
      alert("Ce document n'est pas trouvé dans la liste des documents disponibles")
    }
  }
}
