<nav class="sectionNavbar">
    <mat-toolbar color="primary" class="navbar">
        <span class="logoText">My</span><img src="assets/logos/logo-sociochaux@3x-150x134.png" class="custom-logo" alt="Logo Sociochaux, un lion rugissant vers l’avenir, car Sochaux vivra" decoding="async" srcset="assets/logos/logo-sociochaux@3x-150x134.png 150w, assets/logos/logo-sociochaux@3x-300x269.png 300w, assets/logos/logo-sociochaux@3x-1024x917.png 1024w, assets/logos/logo-sociochaux@3x-768x688.png 768w, assets/logos/logo-sociochaux@3x-1536x1376.png 1536w, assets/logos/logo-sociochaux@3x.png 1563w" class="logo">
        <span class="example-spacer"></span>
        <div>
            <button mat-raised-button color="accent" id="install"><mat-icon>install_mobile</mat-icon>MySocio</button>
        </div>
        <a *ngFor="let menu of menuToDisplay" mat-button class="example-icon navbar-button" [routerLink]="[menu.link]">
            <mat-icon>{{menu.icon}}</mat-icon> {{menu.title}}
        </a>
        <button mat-icon-button fxHide.gt-sm *ngIf="isConnected" [matMenuTriggerFor]="menu">
            <mat-icon>more_vert</mat-icon>
        </button>
        <button mat-button fxHide.lt-md *ngIf="isConnected" [matMenuTriggerFor]="menu">
            <mat-icon>menu</mat-icon> MENU
        </button>
        <mat-menu #menu="matMenu">
            <button mat-menu-item [routerLink]="['/account']">
                <mat-icon>account_circle</mat-icon>
                <span>Mon profil</span>
            </button>
            <button mat-menu-item [routerLink]="['/pronos']">
                <mat-icon>check_box</mat-icon>
                <span>Pronostics</span>
            </button>
            <button mat-menu-item [routerLink]="['/consultations']">
                <mat-icon>how_to_vote</mat-icon>
                <span>Consultations</span>
            </button>
            <button mat-menu-item [routerLink]="['/documents']">
                <mat-icon>folder_open</mat-icon>
                <span>Documents</span>
            </button>
            <button mat-menu-item (click)="disconnect()">
                <mat-icon>logout</mat-icon>
                <span>Déconnexion</span>
            </button>
        </mat-menu>
    </mat-toolbar>
</nav>