import { Component, Inject } from '@angular/core';
import { FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConnectorService, UserVariable } from '../connector.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import {FormsModule} from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { FlexLayoutModule } from '@angular/flex-layout';

export class DialogPwdData {
  email: string = "";
  code?: string = "";
}

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  matcher = new MyErrorStateMatcher();
  message:string = "";

  emailFormControl = new FormControl('');
  passwordFormControl = new FormControl('');

  constructor(
    private http: HttpClient,
    private router: Router,
    private connector: ConnectorService,
    public dialog: MatDialog,
    private currentRoute: ActivatedRoute
  ){}

  ngOnInit(): void {
    if(this.connector.isConnected()){
      this.connector.connectedNavigation();
    }
    this.currentRoute.queryParams
      .subscribe(params => {
        this.message = params['message'];
      }
    );
  }

  connection(){
    if(this.emailFormControl.getRawValue() !== null && this.passwordFormControl.getRawValue() !== null){
      this.connector.connection(this.emailFormControl.getRawValue(), this.passwordFormControl.getRawValue());
    }else{
      alert("L'email et mot de passe sont requis")
    }
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(DialogOverviewExampleDialog, {
      data: {email: this.emailFormControl.getRawValue()},
    });

    dialogRef.afterClosed().subscribe(objectReturned => {
      if(objectReturned !== undefined){
        this.http.post<boolean>(environment.backAPI+'/compte/reinit-pwd', objectReturned).subscribe(
          (result) => {
            alert("Votre demande a bien été prise en compte et un email vient de vous être envoyé");
          },
          (error) => {
            alert("Une erreur est survenue, votre demande n'a pas pu être prise en compte");
          }
        );
      }
    });
  }
}


@Component({
  selector: 'password-page-dialog',
  templateUrl: 'password-page-dialog.html',
  styleUrls: ['./password-page-dialog.css'],
  standalone: true,
  imports: [MatDialogModule, MatFormFieldModule, MatInputModule, FormsModule, FlexLayoutModule, MatButtonModule],
})
export class DialogOverviewExampleDialog {
  constructor(
    public dialogRef: MatDialogRef<DialogOverviewExampleDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogPwdData,
  ) {}

  regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

  onNoClick(): void {
    this.dialogRef.close();
  }
}