export enum PariStatus {
    INACTIVE = "inactive",
    ACTIVE = "active",
    WAITING = "waiting",
    ENDED = "ended",
    CANCELED = "canceled",
}

export class PronosticDto {
    id:string = "";
    date: Date = new Date();
    isLocked: boolean = false;
    option: OptionPariDto = new OptionPariDto();
    compte: any;
}

export class OptionPariDto {
    id:string = "";
    titre: string = "";
    description: string = "";
    score: number = 0;
    isValidated: boolean = false;
    pari:PariDto = new PariDto();
}

export class PariDto {
    id:string = "";
    titre: string = "";
    description: string = "";
    status: PariStatus = PariStatus.ACTIVE;
    date_fin: Date = new Date();
    categorie: CategorieDto = new CategorieDto();
    options: OptionPariDto[] = [];
    selectedOption?: OptionPariDto;
}

export class CategorieDto {
    id:number = 0;
    titre: string = "";
    description: string = "";
    paris: PariDto[] = [];
}

export class CurrentPronos {
    paris: PariDto[] =[];
    pronostics: PronosticDto[] = [];
    historic: PronosticDto[] = [];
    scores: ScorePronoDto[] = [];
    ranks: ScorePronoDto[] = [];
}

export class HistoricProno {
    categorie:string = "";
    pari:string = "";
    option_label:string = "";
    option:OptionPariDto = new OptionPariDto();
    gagnant:string = "";
    score:string = '0';
}

export class ScorePronoDto {
    categorieId: number = 0;
    categorieName: string = "";
    compteId: number = 0;
    username: string|null = null;
    score: number = 0;
    nombre: number = 0;
    last_update: string = "";
}

export class ScorePronoDayDto {
    categorieId: number = 0;
    categorieName: string = "";
    annee: number = 0;
    mois: number = 0;
    jour: number = 0;
    compteId: number = 0;
    username: string|null = null;
    score: number = 0;
    nombre: number = 0;
    last_update: string = "";
}
