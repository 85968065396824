<div fxLayout="column">
    <h1 flex="" fxLayout="row" fxLayoutAlign="center">Connexion à MySociochaux</h1>
    <div flex="" fxLayout="row" fxLayoutAlign="left">
        <form class="connection-form">
            <mat-card *ngIf="message === 'wrong_credentials'">
              <mat-card-content class="bad_credentials">Identifiants incorrects</mat-card-content>
            </mat-card>
            <mat-form-field class="connection-full-width">
                <mat-label>Adresse email</mat-label>
                <input matInput [formControl]="emailFormControl" [errorStateMatcher]="matcher" type="email" placeholder="mon@adresse.fr" value="">
                <mat-hint>L'adresse sur laquelle vous avez reçu votre email d'inscription</mat-hint>
                <mat-error *ngIf="emailFormControl.hasError('email') && !emailFormControl.hasError('required')">
                  Merci d'entrer une adresse valide
                </mat-error>
                <mat-error *ngIf="emailFormControl.hasError('required')">
                  L'email est <strong>obligatoire</strong>
                </mat-error>
            </mat-form-field>
          
            <mat-form-field class="connection-full-width">
              <mat-label>Mot de passe</mat-label>
              <input matInput [formControl]="passwordFormControl" placeholder="Mot de passe" type="password" value="">
            </mat-form-field>
          </form>
    </div>
    <div flex="">&nbsp;</div>
    <h1 flex="" fxLayout="row" fxLayoutAlign="center">
        <button mat-raised-button color="accent" (click)="connection()">Me connecter</button>
    </h1>
    <div flex="">&nbsp;</div>
    <div flex="" fxLayoutAlign="center"><button mat-stroked-button color="primary" (click)="openDialog()">J'ai perdu mon mot de passe</button></div>
    <div flex="">&nbsp;</div>
    <h1 flex="" fxLayout="row" fxLayoutAlign="center">
        <a mat-raised-button color="accent" href="/#/adhesion">Adhérer à Sociochaux</a>
    </h1>
</div>