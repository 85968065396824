import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { ConnectorService, UserVariable } from '../connector.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {

  constructor(
    private http: HttpClient,
    private connector: ConnectorService
  ) {}

  ngOnInit(): void {
    if(!this.connector.isConnected()){
      this.connector.disconnection();
    }
  }

  getUsername():string{
    return this.connector.getValue(UserVariable.USERNAME);
  }

  getEmail():string{
    return this.connector.getValue(UserVariable.EMAIL);
  }
}
