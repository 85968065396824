import { Socio } from "../account/account.dto";

export enum JetonStatus {
    NEW = "new",
    USED = "used",
    EXPIRED = "expired"
}

export enum ConsultationStatus {
    DRAFT = "draft",
    ACTIVE = "active",
    ENDED = "ended",
    CANCELED = "canceled"
}

export enum ConsultationType {
    CREDITS = "credits",
    VALEURS = "valeurs"
}

export class Jeton {
    id:number = 0;
    date_utilisation: Date = new Date();
    status: JetonStatus = JetonStatus.EXPIRED;
    consultation: Consultation = new Consultation();
    socio: Socio = new Socio();
}

export class Consultation {
    id:number = 0;
    status: ConsultationStatus = ConsultationStatus.CANCELED;
    titre: string = "";
    description: string = "";
    date_debut: Date = new Date();
    date_fin: Date = new Date();
    type: ConsultationType = ConsultationType.CREDITS;
    credits: number = 0;
    questions: Question[] = [];
    jetons: Jeton[] = [];
}

export class Question {
    id:number = 0;
    titre: string = "";
    description: string = "";
    obligatoire: boolean = false;
    volontariat: boolean = false;
    lien: string = "";
    ordre:number = 0;
}