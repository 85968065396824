export interface CountryCodes {
    code: string;
    country: string;
}

// https://github.com/google/libphonenumber/blob/master/FAQ.md#why-are-bouvet-island-bv-pitcairn-island-pn-antarctica-aq-etc-not-supported
export const ISO_3166_1_CODES: CountryCodes[] = [
    {code: 'FR', country: 'France'},
    {code: 'CH', country: 'Suisse'},
    {code: 'ES', country: 'Espagne'},
    {code: 'DE', country: 'Allemagne'},
    {code: 'GB', country: 'Royaume-Uni'},
    {code: 'US', country: 'États-Unis d\'Amérique'},
    {code: 'AF', country: 'Afghanistan'},
    {code: 'AX', country: 'Åland Islands'},
    {code: 'AL', country: 'Albanie'},
    {code: 'DZ', country: 'Algérie'},
    {code: 'AS', country: 'Samoa américaines'},
    {code: 'AD', country: 'Andorre'},
    {code: 'AO', country: 'Angola'},
    {code: 'AI', country: 'Anguilla'},
    {code: 'AG', country: 'Antigua-et-Barbuda'},
    {code: 'AR', country: 'Argentine'},
    {code: 'AM', country: 'Arménie'},
    {code: 'AW', country: 'Aruba'},
    {code: 'AU', country: 'Australie'},
    {code: 'AT', country: 'Autriche'},
    {code: 'AZ', country: 'Azerbaïdjan'},
    {code: 'BS', country: 'Bahamas'},
    {code: 'BH', country: 'Bahreïn'},
    {code: 'BD', country: 'Bangladesh'},
    {code: 'BB', country: 'Barbade'},
    {code: 'BY', country: 'Biélorussie'},
    {code: 'BE', country: 'Belgique'},
    {code: 'BZ', country: 'Belize'},
    {code: 'BJ', country: 'Bénin'},
    {code: 'BM', country: 'Bermudes'},
    {code: 'BT', country: 'Bhoutan'},
    {code: 'BO', country: 'Bolivie'},
    {code: 'BQ', country: 'Bonaire'},
    {code: 'BA', country: 'Bosnie-Herzégovine'},
    {code: 'BW', country: 'Botswana'},
    {code: 'BR', country: 'Brésil'},
    {code: 'IO', country: 'Territoire britannique de l\'océan Indien'},
    {code: 'BN', country: 'Brunéi Darussalam'},
    {code: 'BG', country: 'Bulgarie'},
    {code: 'BF', country: 'Burkina Faso'},
    {code: 'BI', country: 'Burundi'},
    {code: 'CV', country: 'Cap-Vert'},
    {code: 'KH', country: 'Cambodge'},
    {code: 'CM', country: 'Cameroun'},
    {code: 'CA', country: 'Canada'},
    {code: 'KY', country: 'Îles Caïmans'},
    {code: 'CF', country: 'République centrafricaine'},
    {code: 'TD', country: 'Tchad'},
    {code: 'CL', country: 'Chili'},
    {code: 'CN', country: 'Chine'},
    {code: 'CX', country: 'Île Christmas'},
    {code: 'CC', country: 'Îles Cocos (Keeling)'},
    {code: 'CO', country: 'Colombie'},
    {code: 'KM', country: 'Comores'},
    {code: 'CG', country: 'Congo'},
    {code: 'CD', country: 'Congo, République démocratique'},
    {code: 'CK', country: 'Îles Cook'},
    {code: 'CR', country: 'Costa Rica'},
    {code: 'CI', country: 'Côte d\'Ivoire'},
    {code: 'HR', country: 'Croatie'},
    {code: 'CU', country: 'Cuba'},
    {code: 'CW', country: 'Curaçao'},
    {code: 'CY', country: 'Chypre'},
    {code: 'CZ', country: 'Tchéquie'},
    {code: 'DK', country: 'Danemark'},
    {code: 'DJ', country: 'Djibouti'},
    {code: 'DM', country: 'Dominique'},
    {code: 'DO', country: 'République dominicaine'},
    {code: 'EC', country: 'Équateur'},
    {code: 'EG', country: 'Égypte'},
    {code: 'SV', country: 'Salvador'},
    {code: 'GQ', country: 'Guinée équatoriale'},
    {code: 'ER', country: 'Érythrée'},
    {code: 'EE', country: 'Estonie'},
    {code: 'SZ', country: 'Eswatini'},
    {code: 'ET', country: 'Éthiopie'},
    {code: 'FK', country: 'Îles Malouines (Falkland)'},
    {code: 'FO', country: 'Îles Féroé'},
    {code: 'FJ', country: 'Fidji'},
    {code: 'FI', country: 'Finlande'},
    {code: 'GF', country: 'Guyane française'},
    {code: 'PF', country: 'Polynésie française'},
    {code: 'GA', country: 'Gabon'},
    {code: 'GM', country: 'Gambie'},
    {code: 'GE', country: 'Géorgie'},
    {code: 'GH', country: 'Ghana'},
    {code: 'GI', country: 'Gibraltar'},
    {code: 'GR', country: 'Grèce'},
    {code: 'GL', country: 'Groenland'},
    {code: 'GD', country: 'Grenade'},
    {code: 'GP', country: 'Guadeloupe'},
    {code: 'GU', country: 'Guam'},
    {code: 'GT', country: 'Guatemala'},
    {code: 'GG', country: 'Guernesey'},
    {code: 'GN', country: 'Guinée'},
    {code: 'GW', country: 'Guinée-Bissau'},
    {code: 'GY', country: 'Guyana'},
    {code: 'HT', country: 'Haïti'},
    {code: 'VA', country: 'Saint-Siège'},
    {code: 'HN', country: 'Honduras'},
    {code: 'HK', country: 'Hong Kong'},
    {code: 'HU', country: 'Hongrie'},
    {code: 'IS', country: 'Islande'},
    {code: 'IN', country: 'Inde'},
    {code: 'ID', country: 'Indonésie'},
    {code: 'IR', country: 'Iran'},
    {code: 'IQ', country: 'Irak'},
    {code: 'IE', country: 'Irlande'},
    {code: 'IM', country: 'Île de Man'},
    {code: 'IL', country: 'Israël'},
    {code: 'IT', country: 'Italie'},
    {code: 'JM', country: 'Jamaïque'},
    {code: 'JP', country: 'Japon'},
    {code: 'JE', country: 'Jersey'},
    {code: 'JO', country: 'Jordanie'},
    {code: 'KZ', country: 'Kazakhstan'},
    {code: 'KE', country: 'Kenya'},
    {code: 'KI', country: 'Kiribati'},
    {code: 'KP', country: 'Corée du nord'},
    {code: 'KR', country: 'Corée du sud'},
    {code: 'KW', country: 'Koweït'},
    {code: 'KG', country: 'Kirghizistan'},
    {code: 'LA', country: 'République démocratique populaire lao'},
    {code: 'LV', country: 'Lettonie'},
    {code: 'LB', country: 'Liban'},
    {code: 'LS', country: 'Lesotho'},
    {code: 'LR', country: 'Libéria'},
    {code: 'LY', country: 'Libye'},
    {code: 'LI', country: 'Liechtenstein'},
    {code: 'LT', country: 'Lituanie'},
    {code: 'LU', country: 'Luxembourg'},
    {code: 'MO', country: 'Macao'},
    {code: 'MG', country: 'Madagascar'},
    {code: 'MW', country: 'Malawi'},
    {code: 'MY', country: 'Malaisie'},
    {code: 'MV', country: 'Maldives'},
    {code: 'ML', country: 'Mali'},
    {code: 'MT', country: 'Malte'},
    {code: 'MH', country: 'Îles Marshall'},
    {code: 'MQ', country: 'Martinique'},
    {code: 'MR', country: 'Mauritanie'},
    {code: 'MU', country: 'Maurice'},
    {code: 'YT', country: 'Mayotte'},
    {code: 'MX', country: 'Mexique'},
    {code: 'FM', country: 'Micronésie'},
    {code: 'MD', country: 'Moldavie'},
    {code: 'MC', country: 'Monaco'},
    {code: 'MN', country: 'Mongolie'},
    {code: 'ME', country: 'Monténégro'},
    {code: 'MS', country: 'Montserrat'},
    {code: 'MA', country: 'Maroc'},
    {code: 'MZ', country: 'Mozambique'},
    {code: 'MM', country: 'Myanmar'},
    {code: 'NA', country: 'Namibie'},
    {code: 'NR', country: 'Nauru'},
    {code: 'NP', country: 'Népal'},
    {code: 'NL', country: 'Pays-Bas'},
    {code: 'NC', country: 'Nouvelle-Calédonie'},
    {code: 'NZ', country: 'Nouvelle-Zélande'},
    {code: 'NI', country: 'Nicaragua'},
    {code: 'NE', country: 'Niger'},
    {code: 'NG', country: 'Nigeria'},
    {code: 'NU', country: 'Niue'},
    {code: 'NF', country: 'Île Norfolk'},
    {code: 'MK', country: 'Macédoine du Nord'},
    {code: 'MP', country: 'Îles Mariannes du Nord'},
    {code: 'NO', country: 'Norvège'},
    {code: 'OM', country: 'Oman'},
    {code: 'PK', country: 'Pakistan'},
    {code: 'PW', country: 'Palaos'},
    {code: 'PS', country: 'Palestine'},
    {code: 'PA', country: 'Panama'},
    {code: 'PG', country: 'Papouasie-Nouvelle-Guinée'},
    {code: 'PY', country: 'Paraguay'},
    {code: 'PE', country: 'Pérou'},
    {code: 'PH', country: 'Philippines'},
    {code: 'PL', country: 'Pologne'},
    {code: 'PT', country: 'Portugal'},
    {code: 'PR', country: 'Porto Rico'},
    {code: 'QA', country: 'Qatar'},
    {code: 'RE', country: 'Réunion'},
    {code: 'RO', country: 'Roumanie'},
    {code: 'RU', country: 'Fédération de Russie'},
    {code: 'RW', country: 'Rwanda'},
    {code: 'BL', country: 'Saint-Barthélemy'},
    {code: 'SH', country: 'Sainte-Hélène, Ascension et Tristan da Cunha'},
    {code: 'KN', country: 'Saint-Kitts-et-Nevis'},
    {code: 'LC', country: 'Sainte-Lucie'},
    {code: 'MF', country: 'Saint-Martin (partie française)'},
    {code: 'PM', country: 'Saint-Pierre-et-Miquelon'},
    {code: 'VC', country: 'Saint-Vincent-et-les Grenadines'},
    {code: 'WS', country: 'Samoa'},
    {code: 'SM', country: 'Saint-Marin'},
    {code: 'ST', country: 'Sao Tomé-et-Principe'},
    {code: 'SA', country: 'Arabie saoudite'},
    {code: 'SN', country: 'Sénégal'},
    {code: 'RS', country: 'Serbie'},
    {code: 'SC', country: 'Seychelles'},
    {code: 'SL', country: 'Sierra Leone'},
    {code: 'SG', country: 'Singapour'},
    {code: 'SX', country: 'Saint-Martin (partie néerlandaise)'},
    {code: 'SK', country: 'Slovaquie'},
    {code: 'SI', country: 'Slovénie'},
    {code: 'SB', country: 'Îles Salomon'},
    {code: 'SO', country: 'Somalie'},
    {code: 'ZA', country: 'Afrique du Sud'},
    {code: 'SS', country: 'Soudan du Sud'},
    {code: 'LK', country: 'Sri Lanka'},
    {code: 'SD', country: 'Soudan'},
    {code: 'SR', country: 'Suriname'},
    {code: 'SJ', country: 'Svalbard et l\'île Jan Mayen'},
    {code: 'SE', country: 'Suède'},
    {code: 'SY', country: 'République arabe syrienne'},
    {code: 'TW', country: 'Taïwan, Province de Chine'},
    {code: 'TJ', country: 'Tadjikistan'},
    {code: 'TZ', country: 'Tanzanie, République-Unie de'},
    {code: 'TH', country: 'Thaïlande'},
    {code: 'TL', country: 'Timor-Leste'},
    {code: 'TG', country: 'Togo'},
    {code: 'TK', country: 'Tokelau'},
    {code: 'TO', country: 'Tonga'},
    {code: 'TT', country: 'Trinité-et-Tobago'},
    {code: 'TN', country: 'Tunisie'},
    {code: 'TR', country: 'Turquie'},
    {code: 'TM', country: 'Turkménistan'},
    {code: 'TC', country: 'Îles Turques-et-Caïques'},
    {code: 'TV', country: 'Tuvalu'},
    {code: 'UG', country: 'Ouganda'},
    {code: 'UA', country: 'Ukraine'},
    {code: 'AE', country: 'Émirats arabes unis'},
    {code: 'UY', country: 'Uruguay'},
    {code: 'UZ', country: 'Ouzbékistan'},
    {code: 'VU', country: 'Vanuatu'},
    {code: 'VE', country: 'Venezuela'},
    {code: 'VN', country: 'Viet Nam'},
    {code: 'VG', country: 'Îles Vierges britanniques'},
    {code: 'VI', country: 'Îles Vierges des États-Unis'},
    {code: 'WF', country: 'Wallis-et-Futuna'},
    {code: 'EH', country: 'Sahara occidental'},
    {code: 'YE', country: 'Yémen'},
    {code: 'ZM', country: 'Zambie'},
    {code: 'ZW', country: 'Zimbabwe'}
];